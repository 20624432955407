:root {
  --appear: appear 0.4s linear 1 forwards;
  --disappear: disappear 0.4s linear 1 forwards;
  --selected: selected 0.4s linear 1 forwards;
  --unselected: unselected 0.4s linear 1 forwards;
  --slide-in-top: slideintop 0.6s linear 0s 1 forwards;
  --slide-in-left: slideinleft 0.5s linear 0.8s 1 forwards;
  --heartbeat: heartbeat 7s infinite 2s;
}

@keyframes appear {
  0%  {transform: scale(0);}
  100% {transform: scale(1);}
}

@keyframes disappear {
  0%  {transform: scale(1);}
  100% {transform: scale(0);}
}

@keyframes selected {
  0%  {background-color: var(--extra-light-red);}
  100% {background-color: var(--light-red);}
}

@keyframes unselected {
  0% {background-color: var(--light-red);}
  100%  {background-color: var(--extra-light-red);}
}

@keyframes slideintop {
  0% {transform: translateY(-100%);}
  70% {transform: translateY(10px);}
  100%  {transform: translateY(0px);}
}

@keyframes slideinleft {
  0% {transform: translateX(-100%);}
  100%  {transform: translateX(0px);}
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  48% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  52% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}