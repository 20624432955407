html {
  //box-sizing: border-box;
}

*, *:before, *:after {
  //box-sizing: inherit;
}

body {
  width:100vw;
  height:100vh;
  margin: 0;
  padding: 0;
  font-family: FuturaStd-Bold;
  overflow: hidden;
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

#root {
  width:100vw;
  height:100vh;
}